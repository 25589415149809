@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&family=Zhi+Mang+Xing&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  --primary-color:  linear-gradient(90deg, #2A44A0 0%, #3353CE 100%);;
  --secondary-color: black;
  --third-color: white;
  --supporting-color: ;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:black;
  color: #EAEAEA;
  font-family: 'Inter', sans-serif;
}



