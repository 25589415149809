.popup {
  align-items: center;
  background-color: #000;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 50%;
  padding: 100px;
  position: fixed;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 1111000000;
}
.videoplayback {
  width: 100% !important;
  height: 90vh !important;
  margin-left: auto;
  margin-right: auto;
}
.imgmodel {
  position: relative;
  /* height: 100%; */
}

.close {
  position: absolute;
  cursor: pointer;
  top: 25px;
  color: grey;
  right: 15px;
}

.theimg {
 max-height: 100vh;
  object-fit: cover;
  /* max-width: 71.641vw; */
  min-height: 37.188vw;
}

.next {
  position: absolute;
  cursor: pointer;
  left: 8.47%;
  top: 50%;
  bottom: 6.25%;
  background: rgba(255, 255, 255, 0.5);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  border-radius: 50px;
}

.previous {
  position: absolute;
  right: 8.47%;
  top: 50%;
  bottom: 6.25%;
  background: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  border-radius: 50px;
}

@media screen and (min-device-width: 481px) and (max-device-width: 900px) {
  .next {
  }

  .previous {
  }
}

@media only screen and (max-width: 480px) {
  .theimg {
    /* height: 80vh; */
    /* max-height: 69vw; */
  }

  .popup {
    padding: 0px;
  }
  .close {
    position: absolute;
    cursor: pointer;
    top: 5px;
    color: grey;
    right: 30px;
    width: 30px;
  }
  .next {
    width: 30px;
    left: 2.47%;
  }

  .previous {
    width: 30px;
    right: 2.47%;
  }
}
@media screen and (min-device-width: 781px) and (max-device-width: 1350px) {
  .close {
    right: 30px;
  }
  .next {
    left: 2.47%;
  }

  .previous {
    right: 2.47%;
  }
}
