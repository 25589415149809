.galrry {
  /* display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; */
  column-count: 3;
  gap:0;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.ceneter{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.navo{
  position: fixed;
  width: 100%;
  background: white;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 10px;
  box-shadow: 0 6px 15px -10px black;;
}
.theNavTxt{
  font-size: 15px;
  color: black;
  font-family: emoji;
  font-weight: 600;
}
.gallerypHOTOS {
  width: 100%;
  /* padding-top: 6px; */
  height: 300px;
  object-fit: cover;
  animation: flip 2s;
  opacity: 1;
  padding: 3px;
}
@media only screen and (max-width: 600px) {
  .galrry {
    /* display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center; */
    display: block;
    gap: 0;
    column-count: 2;
    padding: 3px;
  }
}
